import { roomStatusEnumeration } from 'blog/type-file';

export const OPEN = roomStatusEnumeration.open;
export const BUSY = roomStatusEnumeration.busy;

export enum RoomStatusConvert {
	open = '开放',
	busy = '占用',
}

export enum feeConfigTitle {
	coldWaterFee = '冷水费',
	hotWaterFee = '热水费',
	electricityFee = '电费',
	tvFee = '电视费',
	networkFee = '网络费',
	cleanFee = '卫生费',
}
